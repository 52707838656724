<template>
  <div class="list-area">
    <el-row>
      <el-button
        type="primary"
        v-has="'add'"
        icon="el-icon-plus"
        @click="handleOperate('create')"
        >添加</el-button
      >
    </el-row>
    <el-row :gutter="6">
      <el-col :span="8">
        <el-row type="flex" align="middle">
          <span style="white-space: nowrap">活动开始时间：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            v-model="searchData.date"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-row>
      </el-col>
      <el-col :span="4"
        ><el-button type="primary" icon="el-icon-search" @click="handleSearch()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template slot="incentiveType" slot-scope="{ row }">
        <span>{{ row.incentiveType | typeDesc(incentiveTypeList) }}</span>
      </template>
      <template slot="developUserName" slot-scope="{ row }">
        <span v-if="row.developUserid.includes('all')">all</span>
        <span v-else>{{ row.developUserName.join("；") }}</span>
      </template>
      <template slot="payMoney" slot-scope="{ row }">
        <!-- 充值赠送 -->
        <div>
          <p v-for="(item, index) in row.payMoney" :key="index">
            <template v-if="row.incentiveType == 1 || row.incentiveType == 5">
              {{ `充值${item.menoy}元，赠送${item.pct}%` }}
            </template>
            <template v-if="row.incentiveType == 3 || row.incentiveType == 4">
              {{ `充值${item.menoy}元，赠送${item.giveMenoy}元` }}
            </template>
            <template
              v-if="
                (row.incentiveType == 6 || row.incentiveType == 9) &&
                row.moneyType == 0
              "
            >
              <span v-if="item.upperLimit > 0">{{
                `充值${item.menoy}元，赠送${item.giveMenoy}元,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `充值${item.menoy}元，赠送${item.giveMenoy}元`
              }}</span>
            </template>
            <template
              v-if="
                (row.incentiveType == 6 || row.incentiveType == 9) &&
                row.moneyType == 1
              "
            >
              <span v-if="item.upperLimit > 0">{{
                `充值${item.menoy}元，赠送${item.pct}%,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `充值${item.menoy}元，赠送${item.pct}%`
              }}</span>
            </template>
            <template v-if="row.incentiveType == 7 && row.moneyType == 0">
              <span v-if="item.upperLimit > 0">{{
                `消耗${item.consume}元，赠送${item.giveMenoy}元,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `消耗${item.consume}元，赠送${item.giveMenoy}元`
              }}</span>
            </template>
            <template v-if="row.incentiveType == 7 && row.moneyType == 1">
              <span v-if="item.upperLimit > 0">{{
                `消耗${item.consume}元，赠送${item.pct}%,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `消耗${item.consume}元，赠送${item.pct}%`
              }}</span>
            </template>
            <template v-if="row.incentiveType == 8 && row.moneyType == 0">
              <span v-if="item.upperLimit > 0">{{
                `月日均增投幅度${item.addAmp}%,赠送月份增投金额${item.giveMenoy}元,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `月日均增投幅度${item.addAmp}%,赠送月份增投金额${item.giveMenoy}元`
              }}</span>
            </template>
            <template v-if="row.incentiveType == 8 && row.moneyType == 1">
              <span v-if="item.upperLimit > 0">{{
                `月日均增投幅度${item.addAmp}%,赠送月份增投金额${item.pct}%,上限${item.upperLimit}元`
              }}</span>
              <span v-if="item.upperLimit <= 0">{{
                `月日均增投幅度${item.addAmp}%,赠送月份增投金额${item.pct}%`
              }}</span>
            </template>
          </p>
        </div>
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-tag :type="row.status | tagType(activeStatusList)">
          {{ row.status | typeDesc(activeStatusList) }}
        </el-tag>
      </template>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'edit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          >
          <el-popconfirm
            v-has="'delete'"
            title="确定删除吗？"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" class="ml10" type="text">
              <span class="colorRed">删除</span>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </PageTable>
    <el-drawer
      size="53%"
      :wrapperClosable="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="visible"
    >
      <Detail
        :operationType="operationType"
        :detailData="detailData"
        @submit="handleSubmit"
        @cancel="visible = false"
      />
    </el-drawer>
  </div>
</template>
<script>
import {
  activeStatusList,
  incentiveTypeList,
} from "@/common/constant/constant.js";
import Detail from "./Detail.vue";
import {
  getLaunchPolicyList,
  delLaunchPolicy,
  saveLaunchPolicy,
} from "./indexServe";
import PageTable from "@/components/PageTableSort";
export default {
  name: "activityPolicy",
  components: { Detail, PageTable },
  mounted() {
    this.init();
  },
  data() {
    return {
      activeStatusList,
      incentiveTypeList,
      searchData: {},
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "actName",
          label: "激励名称",
        },
        {
          slot: "incentiveType",
          label: "激励类型",
        },
        {
          slot: "developUserName",
          label: "开发者",
        },
        {
          key: "actStartTime",
          label: "活动开始时间",
          width: 140,
        },
        {
          key: "actEndTime",
          label: "活动结束时间",
          width: 140,
        },
        {
          slot: "payMoney",
          width: 250,
          label: "充值金额阈值及激励比例",
        },
        {
          key: "effectiveDay",
          label: "激励金有效期",
        },
        {
          key: "remark",
          label: "备注",
        },
        {
          slot: "status",
          label: "活动状态",
        },
        {
          key: "createdAt",
          label: "创建时间",
          width: 140,
        },
        {
          key: "updatedAt",
          label: "修改时间",
          width: 140,
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      this.visible = true;
      if (["create"].includes(operationType)) {
        this.detailData = {};
      } else if (operationType == "edit")
        this.detailData = { ...row, actId: row.id };
    },
    handleDelete(row) {
      delLaunchPolicy({ id: row.id }).then((res) => {
        if (res && res.code === 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      formData.effectiveDay = Number(formData.effectiveDay);
      const fcn = saveLaunchPolicy; // 编辑和新建分别调用不同接口
      fcn(formData).then((res) => {
        if (res && res.code === 0) {
          this.visible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },

    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const _params = {
        page: 1,
        size: 10,
        status: -1, // 查全部
        ...this.searchData,
        ...params,
      };
      if (_params.date) {
        _params.actStartStartTime = _params.date[0];
        _params.actStartEndTime = _params.date[1];
        delete _params.date;
      }
      const res = await getLaunchPolicyList(_params);
      if (res) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },

    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
