<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="220px"
    label-suffix="："
  >
    <el-form-item label="激励名称" prop="actName">
      <el-input
        placeholder="请输入激励名称"
        v-model="formData.actName"
      ></el-input>
    </el-form-item>
    <el-form-item label="激励类型" prop="incentiveType">
      <el-select
        filterable
        clearable
        :disabled="disabled"
        v-model="formData.incentiveType"
        placeholder="请选择激励类型"
        @change="handleChangeType"
      >
        <el-option
          v-for="item in incentiveTypeList"
          :key="item.key"
          :value="item.key"
          :label="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="开发者" prop="developUserid">
      <el-select
        filterable
        clearable
        multiple
        v-model="formData.developUserid"
        placeholder="请选择开发者"
      >
        <el-option value="all" label="all"></el-option>
        <el-option
          v-for="item in developUserList"
          :key="item.id"
          :value="item.id"
          :label="item.companyName"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="活动开始时间" prop="actStartTime">
      <el-date-picker
        type="datetime"
        placeholder="请选择活动开始时间"
        v-model="formData.actStartTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="活动结束时间" prop="actEndTime">
      <el-date-picker
        type="datetime"
        placeholder="请选择活动结束时间"
        v-model="formData.actEndTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="激励金有效期限" prop="effectiveDay">
      <el-input
        placeholder="请输入激励金有效期限"
        v-model="formData.effectiveDay"
      ></el-input
      >&nbsp;&nbsp;&nbsp;天
    </el-form-item>
    <el-form-item :label="labelStr" prop="payMoney" required>
      <div v-if="[1, 3, 4, 5].includes(formData.incentiveType)">
        <el-form-item
          v-for="(item, index) in formData.payMoney"
          :key="index"
          :prop="'payMoney.' + index"
          :rules="[{ validator: validatePayMoney }, { required: true }]"
        >
          <span class="mr20">
            充值<el-input
              style="width: 100px"
              v-model.number="item.menoy"
            ></el-input
            >元，赠送
            <template
              v-if="formData.incentiveType == 1 || formData.incentiveType == 5"
            >
              <el-input style="width: 100px" v-model.number="item.pct">
              </el-input
              >%
            </template>
            <template v-else>
              <el-input style="width: 100px" v-model.number="item.giveMenoy">
              </el-input
              >元
            </template>
          </span>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            v-if="index == formData.payMoney.length - 1"
            @click="handleAddItem"
          ></el-button>
          <el-button
            v-if="index !== 0"
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="handleDeleteItem(index)"
          ></el-button>
        </el-form-item>
      </div>
      <div v-else>
        <div v-if="[6, 7, 8, 9].includes(formData.incentiveType)">
          <el-radio v-model="formData.moneyType" :label="0">数值</el-radio>
          <el-radio v-model="formData.moneyType" :label="1">百分比</el-radio>
        </div>
        <el-form-item
          v-for="(item, index) in formData.payMoney"
          :key="index"
          :prop="'payMoney.' + index"
          :rules="[{ validator: validatePayMoney1 }, { required: true }]"
        >
          <span class="mr20">
            {{ textStr }}
            <el-input
              style="width: 100px"
              v-model.number="item.consume"
              v-if="formData.incentiveType == 7"
            ></el-input>
            <el-input
              style="width: 100px"
              v-model.number="item.addAmp"
              v-if="formData.incentiveType == 8"
            ></el-input>
            <el-input
              style="width: 100px"
              v-model.number="item.menoy"
              v-if="formData.incentiveType != 7 && formData.incentiveType != 8"
            ></el-input>
            {{ formData.incentiveType == 8 ? "%" : "元" }}，{{ payTypeStr }}
            <template v-if="formData.moneyType == 1">
              <el-input style="width: 100px" v-model.number="item.pct">
              </el-input>
              %，
            </template>
            <template v-else>
              <el-input style="width: 100px" v-model.number="item.giveMenoy">
              </el-input>
              元，
            </template>
            上限
            <el-input
              style="width: 100px"
              v-model.number="item.upperLimit"
            ></el-input>
            元
          </span>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            v-if="index == formData.payMoney.length - 1"
            @click="handleAddItem"
          ></el-button>
          <el-button
            v-if="index !== 0"
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="handleDeleteItem(index)"
          ></el-button>
        </el-form-item>
      </div>
    </el-form-item>
    <el-form-item
      label="新客续充值时间限制"
      prop="newRecharge"
      v-if="formData.incentiveType == 6"
    >
      <el-input
        placeholder="请输入新客续充值时间限制"
        v-model.number="formData.newRecharge"
      ></el-input
      >&nbsp;&nbsp;&nbsp;天
    </el-form-item>
    <el-form-item
      label="月消耗最低额度"
      prop="monthConsume"
      v-if="formData.incentiveType == 8"
    >
      <el-input
        placeholder="请输入月消耗最低额度"
        v-model="formData.monthConsume"
      ></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input
        type="textarea"
        v-model="formData.remark"
        placeholder="请输入备注"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleSubmit()">立即提交</el-button>
      <el-button @click="handleCancel()">返回</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { incentiveTypeList, MAXSIZE } from "@/common/constant/constant.js";
// import UploadFile from "@/components/UploadFile.vue";
import { getDeveloperUser } from "@/common/js/api";
export default {
  // components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    operationType: { default: "" },
  },
  data() {
    const checkNum = (rule, value, callback) => {
      // 校验数据大于0
      // eslint-disable-next-line no-useless-escape
      let regVersion = /^\+?[1-9][0-9]*$/; //大于等于1的纯数字
      if (regVersion.test(value)) {
        // 合法的
        return callback();
      } else {
        return callback(new Error("请输入大于0的正整数"));
      }
    };
    const checkNum1 = (rule, value, callback) => {
      // let regVersion = /^([1-9][0-9]*|0)(\.\d{1,2})?\%?$/; //保留两位小数
      let regVersion = /^([1-9][0-9]*|0)(\.\d{1,2})?%?$/; //保留两位小数
      if (!regVersion.test(value) || parseFloat(value) < 5000) {
        // 不合法的
        return callback(new Error("请输入大于等于5000并且保留两位小数的数字"));
      } else {
        return callback();
      }
    };
    const checkActEndTime = (rule, value, callback) => {
      const startTime = new Date(this.formData.actStartTime).getTime();
      const endTime = new Date(value).getTime();
      if (value && endTime < startTime) {
        return callback(new Error("活动结束时间不能小于活动开始时间"));
      } else {
        callback();
      }
    };
    return {
      incentiveTypeList,
      developUserList: [],
      formData: {},
      rules: {
        actName: [{ required: true, message: "请输入激励名称" }],
        effectiveDay: [
          { required: true, message: "请输入激励金有效期限" },
          { validator: checkNum },
        ],
        newRecharge: [
          { required: true, message: "请输入新客续充值时间限制" },
          { validator: checkNum },
        ],
        monthConsume: [
          { required: true, message: "请输入月消耗最低额度" },
          { validator: checkNum1 },
        ],
        incentiveType: [{ required: true, message: "请选择激励类型" }],
        developUserid: [{ required: true, message: "请选择开发者" }],
        actStartTime: [{ required: true, message: "请选择活动开始时间" }],
        actEndTime: [
          { required: true, message: "请选择活动结束时间" },
          { validator: checkActEndTime },
        ],
      },
    };
  },
  created() {
    this.getDeveloperUser();
  },
  computed: {
    disabled() {
      return this.operationType === "edit";
    },
    labelStr() {
      if (this.formData.incentiveType == 3) {
        return "直客充值金额阈值及激励比例";
      } else if (this.formData.incentiveType == 4) {
        return "代理充值金额阈值及激励比例";
      } else if (this.formData.incentiveType == 5) {
        return "续充值金额阈值及激励比例";
      } else if (this.formData.incentiveType == 6) {
        return "新客续充值金额阈值及激励比例";
      } else if (this.formData.incentiveType == 7) {
        return "消耗返利金额阈值及激励比例";
      } else if (this.formData.incentiveType == 8) {
        return "月增长消耗返利阈值及激励比例";
      } else {
        return "充值金额阈值及激励比例";
      }
    },
    textStr() {
      if (this.formData.incentiveType == 7) {
        return "消耗";
      } else if (this.formData.incentiveType == 8) {
        return "月日均增投幅度";
      } else {
        return "充值";
      }
    },
    payTypeStr() {
      if (this.formData.incentiveType == 8) {
        return "赠送月份增投金额";
      } else {
        return "赠送";
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const _data = JSON.parse(JSON.stringify(this.formData));
          const { developUserid } = _data;
          if (developUserid.includes("all")) {
            // 选择所有开发者时，给后端传all
            _data.developUserid = ["all"];
          }
          if (_data.incentiveType != 8) {
            _data.monthConsume = 0;
          }
          _data.monthConsume = Number(_data.monthConsume);
          _data.payMoney.map((i) => {
            if (
              _data.incentiveType == 1 ||
              _data.incentiveType == 5 ||
              (_data.moneyType == 1 &&
                [6, 7, 8, 9].includes(_data.incentiveType))
            ) {
              // 充值赠送，将新客户赠送字段置为0
              i.giveMenoy = 0;
            } else {
              // 新客户充值赠送，充值赠送字段置为0
              // 新客首充 月消耗增长返利 月消耗返利 新客续充值 为数值时赠送比例为0
              if (
                _data.moneyType != 1 &&
                [0, 1, 2, 3, 4, 5, 7, 8, 9].includes(_data.incentiveType)
              ) {
                i.pct = 0;
              }
              if (_data.moneyType == 1 && [9].includes(_data.incentiveType)) {
                i.giveMenoy = 0;
              }
            }
            if (_data.incentiveType != 7) {
              i.consume = 0;
            }
            if (_data.incentiveType != 8) {
              i.addAmp = 0;
            }
            if ([0, 1, 2, 3, 4, 5].includes(_data.incentiveType)) {
              i.upperLimit = 0;
            }
            if (!i.upperLimit) {
              i.upperLimit = 0;
            }
            // if (_data.incentiveType == 1||_data.incentiveType == 5) {
            //   // 充值赠送，将新客户赠送字段置为0
            //   i.giveMenoy = 0;
            // } else {
            //   // 新客户充值赠送，充值赠送字段置为0
            //   i.pct = 0;
            // }
            return i;
          });
          if (_data.incentiveType == 5 || _data.incentiveType == 1) {
            _data.moneyType = 1;
          }
          if (_data.incentiveType == 4 || _data.incentiveType == 3) {
            _data.moneyType = 0;
          }
          this.$emit("submit", _data);
        }
      });
    },
    handleChangeType() {
      this.formData.payMoney = [
        {
          menoy: null,
          pct: null,
          giveMenoy: null,
          consume: null,
          addAmp: null,
          upperLimit: null,
        },
      ];
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleAddItem() {
      this.formData.payMoney.push({
        menoy: null,
        pct: null,
        giveMenoy: null,
        consume: null,
        addAmp: null,
        upperLimit: null,
      });
    },
    handleDeleteItem(index) {
      this.formData.payMoney.splice(index, 1);
    },
    async getDeveloperUser() {
      const res = await getDeveloperUser({
        page: 1,
        backendType: 3,
        size: MAXSIZE,
      });
      this.developUserList = res.data.list;
    },
    // 其他
    initData(data = {}) {
      const _formData = {
        actName: "",
        effectiveDay: "",
        newCustomersMoney: 0,
        newRecharge: 0,
        monthConsume: 0,
        moneyType: 0, //0数值 1百分比
        incentiveType: "",
        developUserid: [],
        actStartTime: "",
        actEndTime: "",
        payMoney: [
          {
            menoy: null,
            pct: null,
            giveMenoy: null,
            consume: null,
            addAmp: null,
            upperLimit: null,
          },
        ],
        remark: "",
        ...data,
      };
      this.formData = _formData;
      // this.$nextTick(() => {
      if (this.operationType === "create") {
        this.$refs.formRef && this.$refs.formRef.resetFields();
      }
      // });
    },
    validatePayMoney(rule, value, callback) {
      if (
        this.formData.incentiveType == 1 ||
        this.formData.incentiveType == 5
      ) {
        // 充值赠送
        if (value.menoy === "" || value.pct === "") {
          callback(new Error("请输入充值金额和赠送百分比"));
        } else {
          callback();
        }
      } else {
        // 新用户赠送
        if (value.menoy === "" || value.giveMenoy === "") {
          callback(new Error("请输入充值金额和赠送金额"));
        } else {
          callback();
        }
      }
    },
    validatePayMoney1(rule, value, callback) {
      if (this.formData.moneyType == 1) {
        //数值类型
        if ([6, 9].includes(this.formData.incentiveType)) {
          if (value.menoy === "" || value.pct === "") {
            callback(new Error("请输入充值金额和赠送百分比"));
          } else {
            callback();
          }
        } else if ([7].includes(this.formData.incentiveType)) {
          if (value.consume === "" || value.pct === "") {
            callback(new Error("请输入消耗金额和赠送百分比"));
          } else {
            callback();
          }
        } else if ([8].includes(this.formData.incentiveType)) {
          if (value.addAmp === "" || value.pct === "") {
            callback(new Error("请输入月日均增投幅度和赠送百分比"));
          } else {
            callback();
          }
        }
      } else {
        //百分比
        if ([6, 9].includes(this.formData.incentiveType)) {
          if (value.menoy === "" || value.giveMenoy === "") {
            callback(new Error("请输入充值金额和赠送金额"));
          } else {
            callback();
          }
        } else if ([7].includes(this.formData.incentiveType)) {
          if (value.consume === "" || value.giveMenoy === "") {
            callback(new Error("请输入消耗金额和赠送金额"));
          } else {
            callback();
          }
        } else if ([8].includes(this.formData.incentiveType)) {
          if (value.addAmp === "" || value.giveMenoy === "") {
            callback(new Error("请输入月日均增投幅度和赠送金额"));
          } else {
            callback();
          }
        }
      }
    },
  },
  watch: {
    detailData: {
      immediate: true,
      handler(data) {
        if (JSON.stringify(data) == "{}") {
          data.effectiveDay = 30;
          data.newRecharge = 30;
        }
        this.initData(data);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.el-input,
.el-select,
.el-textarea {
  width: 500px;
}
</style>
